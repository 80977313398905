import {
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  IconButtonProps,
  Box,
  Switch,
} from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import React from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ToggleOnIcon from '@material-ui/icons/ToggleOn'
import ToggleOffIcon from '@material-ui/icons/ToggleOff'

interface ICardAddOnProps {
  children: any
  style?: Record<string, string>
  contentStyle?: Record<string, string>
  section?: {
    type?: string
    header?: {
      title?: any
      onClickAction?: (event: React.MouseEvent<HTMLButtonElement>) => void
      icon?: any
      disabled?: boolean
    }
    footer?: {
      onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
      label?: string
    }
    ShowMore?: {
      value?: boolean
      onClickAction?: (event: React.MouseEvent<HTMLButtonElement>) => void
    }
    Switch?: {
      label?: string
      value?: boolean
      onClickAction?: (event: React.MouseEvent<HTMLButtonElement>) => void
    }
  }
}

export function CardContents(props: ICardAddOnProps) {
  const { children, section, style, contentStyle } = props

  return (
    <Box className="card-form" style={style}>
      <Card>
        {section?.header && (
          <CardHeader
            disableTypography
            title={<span className="smTitle ">{section?.header?.title} </span>}
            action={
              <>
                <IconButton
                  disabled={section?.header?.disabled}
                  className="card-header-btn"
                  aria-label="edit"
                  onClick={section?.header?.onClickAction}
                >
                  {section?.header?.icon}
                </IconButton>

                {section?.type == 'Calendar' ? (
                  <>
                    {section?.Switch ? (
                      <IconButton
                        className="card-header-btn"
                        onClick={section?.Switch?.onClickAction}
                      >
                        <span
                          style={{
                            height: '10px',
                            fontSize: '13px',
                            color: 'black',
                            marginRight: '5px',
                          }}
                        >
                          {section?.Switch?.label}
                        </span>
                        {section?.Switch?.value ? (
                          <ToggleOnIcon />
                        ) : (
                          <ToggleOffIcon style={{ color: 'black' }} />
                        )}
                      </IconButton>
                    ) : null}

                    <IconButton
                      className="card-header-btn"
                      onClick={section?.ShowMore?.onClickAction}
                      aria-label="show more"
                    >
                      {section?.ShowMore?.value ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </IconButton>
                  </>
                ) : null}
              </>
            }
          />
        )}

        <CardContent style={contentStyle}>{children}</CardContent>
      </Card>
      {section?.footer && (
        <Box className="btn-container">
          <Button
            color="primary"
            variant="contained"
            onClick={section?.footer?.onClick}
          >
            {section?.footer?.label}
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default CardContents
