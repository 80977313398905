import React from 'react'
interface ContentWrapperProps {
  children?: any
  footer?: boolean
  page?: string
  float?: boolean
  floatTotalAmt?: boolean
  search?: boolean
  multiDynamicInfo?: boolean
  singleDynamicInfo?: boolean
  singleSubHeaderSearchHeader?: boolean
  doubleSubHeaderSearchHeader?: boolean
  dynamicInfoSearch?: boolean
  multiDynamicInfoSearch?: boolean
  multiDynamicSubInfoSearch?: boolean
  tabs?: boolean
  attendanceTabs?: boolean
  multiDynamicInfoFullYearHeader?: boolean
  topSearch?: boolean
  searchHeaderMargincontent?: boolean
  yearTabs?: boolean
  tab?: boolean
  subheaderDate?: boolean
  tabSearch?: boolean
  singlesubHeader?: boolean
  attendancetable?: boolean
  tabsLHDN?: boolean
  LeaveSearch?: boolean
  PeriodMonth?: boolean
  ProfilePage?: boolean
  bulletin?: boolean
  tabPolicy?: boolean
  fixed?: boolean
  LeavePolicy?: boolean
  autocompleteCompany?: boolean
  multiSelectDynamicInfo?: boolean
  multiDynamicInfoDateSearchSelect?: boolean
  singleDynamicInfoTabForm?: boolean
  reportingList?: boolean
  multiDynamicInfoTabSearch?: boolean
}
export const ContentWrapper = (props: ContentWrapperProps) => {
  const {
    children,
    footer,
    LeavePolicy,
    search,
    float,
    tabSearch,
    PeriodMonth,
    bulletin,
    tab,
    tabPolicy,
    singlesubHeader,
    floatTotalAmt,
    multiDynamicInfo,
    singleDynamicInfo,
    fixed,
    singleSubHeaderSearchHeader,
    doubleSubHeaderSearchHeader,
    multiDynamicInfoFullYearHeader,
    dynamicInfoSearch,
    multiDynamicInfoSearch,
    searchHeaderMargincontent,
    tabs,
    attendanceTabs,
    topSearch,
    multiDynamicSubInfoSearch,
    yearTabs,
    subheaderDate,
    attendancetable,
    ProfilePage,
    tabsLHDN,
    LeaveSearch,
    autocompleteCompany,
    multiSelectDynamicInfo,
    multiDynamicInfoDateSearchSelect,
    singleDynamicInfoTabForm,
    reportingList,
    multiDynamicInfoTabSearch,
  } = props
  var footing = ''

  if (footer == true && float == true) {
    footing = 'with-footer-n-float'
  } else if (footer == true && float == null) {
    footing = 'with-footer'
  } else if (footer == null && float == true) {
    footing = 'with-float'
  } else if (footer == true && floatTotalAmt == true) {
    footing = 'with-float-amt'
  }
  //  else if (footer == null && search == true) {
  //   footing = 'with-search'
  // }
  return (
    <div
      className={`content-wrapper ${footing} 
      ${tabs && 'with-tab'}
      ${yearTabs && 'with-year-tab'}
      ${tab && 'with-tabs'}
      ${tabSearch && 'with-tabSearch'}
      ${singlesubHeader && 'single-subHeader-searchHeader2'}
      ${tabsLHDN && 'with-tabsLHDN'}
      ${LeavePolicy && 'with-LeavePolicy'}
      ${LeaveSearch && 'with-search2'}
      ${PeriodMonth && 'period-month'}
      ${ProfilePage && 'profile-page'}
      ${bulletin && 'with-bulletin'}
      ${topSearch && 'with-topSearch'}
      ${search && 'with-search'} 
      ${multiDynamicInfo && `multiple-dynamicInfo`}
      ${singleDynamicInfo && `single-dynamicInfo`}
      ${singleSubHeaderSearchHeader && `single-subHeader-searchHeader`}
      ${doubleSubHeaderSearchHeader && `double-subHeader-searchHeader`}
      ${attendanceTabs && `attendance-tabs`}
      ${attendancetable && `attendance-tabs-table`}
      ${dynamicInfoSearch && `dynamicinfo-search`}
      ${tabPolicy && 'with-tabPolicy'}
      ${subheaderDate && 'with-date'}
      ${fixed && 'fixed'}
      ${multiDynamicInfoSearch && `multi-dynamicinfo-search`}
      ${searchHeaderMargincontent && `search-header-margin-content`}
      ${multiDynamicSubInfoSearch && `multi-dynamicSubinfo-search`}
      ${multiDynamicInfoFullYearHeader && `multi-dynamicinfo-full-year-header`}
      ${autocompleteCompany && `with-autocomplete-company`}
      ${multiSelectDynamicInfo && `multi-select-dynamicinfo`}
      ${multiDynamicInfoDateSearchSelect &&
        `multi-dynamicInfo-date-search-select`}
      ${singleDynamicInfoTabForm && 'single-dynamicInfo-tab-form'}
      ${reportingList && 'reporting-list'}
      ${multiDynamicInfoTabSearch && 'multi-dynamicInfo-tab-search'}
      `}
      // style={{ marginTop: search ? '38px' : null }}
    >
      {children}
    </div>
  )
}
